import React from "react";
import styled from "styled-components";

import { useRouter } from "next/router";
import { Link } from "react-scroll";
import LinkHref from "../../Link-href";
import { useLocales } from "../../../hooks";
import Bulk from "../../../../assets/icons/bulk.png";
import BulkSVG from "../../../../assets/icons/bulk.svg";

const NavStyled = styled.div`
  display: flex;
  align-items: center;
  & a:not([href]) {
    cursor: pointer;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #a2a4b1 !important;
    padding: 1%;
    white-space: nowrap;
    display: block;
  }
  & a {
    cursor: pointer;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #a2a4b1 !important;
    padding: 1%;
  }

  @media (max-width: 768px) {
    min-width: 300px;
    flex-direction: column;
    justify-content: space-evenly;
    background: #2e2f55;
    opacity: 0.9;
    position: fixed;
    height: 100%;
    width: 100%;
    text-align: left !important;
    transition: transform 0.3s ease-in-out;
    background-color: #2e2f55;
    position: fixed;
    z-index: -1;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
    top: 0;
    left: 0;
    opacity: 0.9;
    transition: transform 0.3s ease-in-out;

    & a:not([href]),
    a {
      font-size: 40px !important;
      white-space: nowrap;
      display: block;
      margin-right:2px ;
    }
  }
`;

const BulkStyled = styled(BulkSVG)`
  width: 110px;
  height: 120px;
  cursor: pointer;
  @media (max-width: 768px) {
    display: none;
  }
`;

const LastStyled = styled.a`
  display: none !important;
  @media (max-width: 768px) {
    display: block !important;
    text-decoration:none ;
  }
`;

const RightNav = ({ open }) => {
  const { trans } = useLocales();
  const router = useRouter();
  return (
    <NavStyled bg={router.pathname == "/" ? "#FFFFFF" : "#2E2F55"} open={open}>
      {router.pathname == "/bulk-order" ? (
        <LinkHref route="/">
          <a>{trans("nav.bulk.Home")}</a>
        </LinkHref>
      ) : null}
      <Link to="Benefits" smooth={true} duration={100}>
        {trans("nav.home.bulk.Benefits")}
      </Link>
      <Link to="App Feautures" smooth={true} duration={100}>
        {trans("nav.home.bulk.AppFeautures")}
      </Link>
      <Link to="Services" smooth={true} duration={100}>
        {trans("nav.home.bulk.Services")}
      </Link>
      {router.pathname == "/" ? (
        <Link to="How it Works" smooth={true} duration={100}>
          {trans("nav.home.HowItWorks")}
        </Link>
      ) : null}
      <Link to="FAQ" smooth={true} duration={100}>
        {trans("nav.home.bulk.FAQ")}
      </Link>
      {router.pathname == "/" ? (
        <Link to="Testimonials" smooth={true} duration={100}>
          {trans("nav.home.Testimonials")}
        </Link>
      ) : null}
      {router.pathname == "/" ? (
        <>
          <LinkHref route="/bulk-order" >
            <a className="p-0" aria-label="makesy bulk order page">
              <BulkStyled />
            </a>
          </LinkHref>
          <LinkHref route="/bulk-order">
            <LastStyled>{trans("nav.home.BulkOrder?")}</LastStyled>
          </LinkHref>
        </>
      ) : null}

      {router.pathname == "/bulk-order" ? (
        <Link to="Order Now" smooth={true} duration={100}>
          {trans("nav.bulk.OrderNow")}
        </Link>
      ) : null}
      {router.pathname == "/bulk-order" ? (
        <Link to="Case Studies" smooth={true} duration={100}>
          {trans("nav.bulk.CaseStudies")}
        </Link>
      ) : null}
    </NavStyled>
  );
};

export default RightNav;
