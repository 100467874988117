import styled from "styled-components";

const Button = styled.button`
  display: flex;
  width:  ${({footer}) => (footer ? "160px" : "200px")};
  height:  ${({footer}) => (footer ? "55px" : "60px")};
  background: ${(props) => (props.dark_mood ? "#FFFFFF" : "#2E2F55")};
  border-radius: 10px;
  padding: 1% 1% 2% 1%;
  justify-content: center;
  align-items: center;
  margin-bottom: 2%;
  & * {
    color: ${(props) => (props.dark_mood ? "#2E2F55" : "#FFFFFF")};
  }
  & .icon > path {
    fill: ${(props) => (props.dark_mood ? "#2E2F55" : "#FFFFFF")};
  }


`;

const Text = styled.div`
  font-size: 12px;
  line-height: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  & > strong {
    color: ${(props) => props.color};
    font-size: 20px;
  }
 
`;

export const ButtonDownload = ({
  footer,
  dark,
  icon: Icon,
  title,
  subTitle,
  type,
  link,
  ...rest
}) => {
  return (
    <Button
    footer={footer}
    onClick={()=> window.open(link, "_blank")}
     dark_mood={dark} {...{ ...rest }}>
      <Icon
        className="icon mx-2"
        width="25"
        height="25"
      />
      <Text>
        <p >{title}</p>
        <strong >{subTitle}</strong>
      </Text>
    </Button>
  );
};
