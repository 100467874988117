import styled from "styled-components";
import Image from "next/image";
import Link from "next/link";
import { useState } from "react";

import { ReactComponent as GooglePlay } from "../../../../assets/icons/googlePlay.svg";
import { ReactComponent as AppStore } from "../../../../assets/icons/appStore.svg";
import { ButtonDownload } from "../../Button";
// import logo from "../../../../assets/images/logo(1).png";
import Logo from "../../../../assets/images/header-logo.svg";

import Send from "../../../../assets/icons/send.svg";
import Facebook from "../../../../assets/icons/facebook.svg";
import LinkedIn from "../../../../assets/icons/linkedin.svg";
import Twitter from "../../../../assets/icons/twitter.svg";
import Instagram from "../../../../assets/icons/instagram.svg";
import { useLocales } from "../../../hooks";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { Loader } from "../../loader";

const FooterStyle = styled.footer`
clip-path: polygon(0px 18%, 100% 0px, 100% 100%, 0% 100%);
  overflow: hidden;
  background: #2e2f55;
  & .mt-5 {
    margin-top: 6rem !important;
  }
  @media (max-width: 768px) {
    clip-path: polygon(0px 0%, 100% 0px, 100% 100%, 0% 100%);
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  & > * {
    margin-top: 2%;
  }

`;

const Title = styled.p`
  font-size: 25px;
  line-height: 35px;
  color: #ffffff;
  margin-top: 2%;
`;
const Text = styled.p`
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
`;
const Input = styled.input`
  background: #fafafa;
  border: 1px solid rgba(186, 187, 194, 0.3);
  box-sizing: border-box;
  padding: 2%;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px 0px 0px 10px;
  height: 50px;
  border-right-width: 0;
  /* max-width: 650px; */
`;

const SendButt = styled.button`
  width: 60px;
  height: 51px;
  border-radius: 0px 4px 4px 0px;
  border-width: 0;
  background: #69a0a3;
  color: #fff;
`;
const Message = styled.div`
  width: 50px;
  width: 100%;
  color: #69a0a3;
  padding: 10px;
  border-radius: 10px;
`;
const ErrorMessage = styled.div`
  display: ${({ error }) => (error ? "block" : "none")};
  color: #f44336;
  height: 20px;
  font-size: 15px;
  margin: 5px 0;
`;

export default function Footer() {
  const { trans } = useLocales();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const onSubmit = (data) => {
    setLoading(true);
    axios
      .post("https://rn.makesy.app/public/newsletter", data)
      .then((response) => {
        setLoading(false);
        setMessage(true);
      });
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  return (
    <>
      <FooterStyle className="row m-0 p-3 p-lg-5 ">
        <div className="col-12 d-flex flex-column flex-md-row align-items-center align-items-md-end justify-content-between">
          <Container className="col-12 col-sm-10 col-md-6 col-lg-5 my-1 py-1 my-md-5 py-md-5 mt-md-5 ps-0 ms-0 ps-xxl-5 ms-xxl-5">
            <Logo width="150" height="40"/>
            <Title>{trans("footer.left.title")}</Title>
            <form onSubmit={handleSubmit(onSubmit)} className="col-12 row">
              <Controller
                control={control}
                name="email"
                defaultValue=""
                render={({ ...field }) => (
                  <Input
                    autoComplete="new-password"
                    disabled={message}
                    style={{ width: "70%" }}
                    {...register("email", {
                      pattern: {
                        value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                        message: "Please Enter Correct Email",
                      },
                      required: true,
                    })}
                    placeholder="Your email"
                  />
                )}
              />


              <SendButt disabled={message} aria-label="submit">
                {loading ? <Loader /> : <Send width="18" height="18" />}
              </SendButt>
              <ErrorMessage error={errors.email?.message}>
                {errors.email?.message}
              </ErrorMessage>

              {message && <Message>It has been sent successfully, Thank you!</Message>}
            </form>

            <ButtonsContainer className="col-7 col-lg-6 ">
              <Link href="https://www.facebook.com/makesy">
                <a target="_blank" aria-label="Makesy facebook page">
                  <Facebook width="24" height="25" />
                </a>
              </Link>
              <Link href="https://www.linkedin.com/company/makesy/">
                <a target="_blank" aria-label="Makesy LinkedIn page">
                  <LinkedIn width="24" height="25" />
                </a>
              </Link>
              <Link href="https://twitter.com/makesyapp?lang=en">
                <a target="_blank" aria-label="Makesy Twitter page">
                  <Twitter width="24" height="25" />
                </a>
              </Link>
              <Link href="https://www.instagram.com/makesy.app/">
                <a target="_blank" aria-label="Makesy Instagram page" >
                  <Instagram width="50" height="25" />
                  {/* <Image src={Instagram} alt="" /> */}
                </a>
              </Link>
            </ButtonsContainer>
          </Container>

          <Container className="col-12 col-sm-10 col-md-6 mt-4 my-md-5 py-md-5">
            <Title className="mb-sm-3">
              {trans("footer.right.title")}
            </Title>
            <Text className="col-12 col-lg-10 col-xxl-9">
              {trans("footer.right.text")}
            </Text>
            <div className=" col-12 col-sm-11 col-xxl-8  row m-0 p-0 d-xl-flex  justify-content-start">
              <ButtonDownload
                className="col-12 col-sm-5 me-2"
                title="Get it on"
                subTitle="Google Play"
                dark={true}
                icon={GooglePlay}
                link="https://play.google.com/store/apps/details?id=com.makesymobile"
                footer={true}
              />
              <ButtonDownload
                className="col-12 col-sm-5"
                title="Download on the"
                subTitle="App Store"
                dark={true}
                icon={AppStore}
                link="https://apps.apple.com/jo/app/makesy/id1559418686"
                footer={true}
              />
            </div>
          </Container>
        </div>
      </FooterStyle>
    </>
  );
}
