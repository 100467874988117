import styled from "styled-components";
import { useRouter } from "next/router";
import Burger from "./Burger";
import Link from 'next/link'
import Logo from "../../../../assets/images/logo-dark.svg";
import Logo2 from "../../../../assets/images/header-logo.svg";
import { useState } from "react";

const NavStyled = styled.div`
  width: 100%;
  height: 71px;
  align-items: center;
  background: ${(props) => props.bg};
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  position: sticky;
  top: 0;
  z-index: 20;
`;

const ImageContainer = styled.div`
cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function Nav() {
  const [open, setOpen] = useState(false)

  const router = useRouter();

  if (router.pathname === "/workshop" || router.pathname === "/privacy-policy")
    return <>
      <NavStyled
        className="d-flex  flex-row-reverse  flex-md-row justify-content-start justify-content-md-around px-4"
        bg={router.pathname == "/" ? "#FFFFFF" : "#2E2F55"}
      >
        <Link href='/' passHref>
          <ImageContainer className="col-md-2 col-5 ">
            {router.pathname == "/" && !open ? (
              <Logo width="150" height="40" />

            ) : (
              <Logo2 width="150" height="40" />
            )}
          </ImageContainer>
        </Link>
      </NavStyled>
    </>

  return (
    <>
      <NavStyled
        className="d-flex  flex-row-reverse  flex-md-row justify-content-start justify-content-md-around px-4"
        bg={router.pathname == "/" ? "#FFFFFF" : "#2E2F55"}
      >
        <Link href='/' passHref>
          <ImageContainer className="col-md-2 col-5 ">
            {router.pathname == "/" && !open ? (
              <Logo width="150" height="40" />

            ) : (
              <Logo2 width="150" height="40" />
            )}
          </ImageContainer>
        </Link>

        <Burger
          open={open}
          setOpen={setOpen}
        />
      </NavStyled>
    </>
  );
}
