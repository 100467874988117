import { useEffect, useState } from "react";
import "../../styles/globals.css";
import "bootstrap/dist/css/bootstrap.css";
import Layout from "../../src/components/layout";
import { I18nProvider } from "../../src/locales";
import "../../styles/fonts.css";
import makesyImg from "../../assets/image.json";
import Head from "next/head";
import SettingsProvider from "../components/orderNow/orderContext";
import Script from "next/script";

function MyApp({ Component, pageProps }) {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(false);
  }, []);
  return (
    <>
      <I18nProvider>
        <Script
          src="https://www.googletagmanager.com/gtag/js?id=GTM-MCJNBD6"
          strategy="afterInteractive"
        />
        <Script id="google-analytics" strategy="afterInteractive">
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'GTM-MCJNBD6');
        `}
        </Script>
        <Script
          id="zoho"
          dangerouslySetInnerHTML={{
            __html: `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "26cd3aaaa0ccae22d201738131cf930294eccc93145b28acfccae71df086d00c1a2010ab7b6727677d37b27582c0e9c4", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);`,
          }}
          strategy="afterInteractive"
        />

        <Script
          id="zoho"
          dangerouslySetInnerHTML={{
            __html: `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "26cd3aaaa0ccae22d201738131cf930294eccc93145b28acfccae71df086d00c1a2010ab7b6727677d37b27582c0e9c4", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);`,
          }}
        />
        <Script strategy="beforeInteractive">
          {`
    var w = window;
    var p = w.location.protocol;
    if (p.indexOf("http") < 0) {
      p = "http:";
    }
    var d = document;
    var f = d.getElementsByTagName('script')[0], s = d.createElement('script');
    s.type = 'text/javascript';
    s.async = false;
    if (s.readyState) {
      s.onreadystatechange = function () {
        if (s.readyState == "loaded" || s.readyState == "complete") {
          s.onreadystatechange = null;
          try {
            loadwaprops("3zf95eabaef38a64147ef7c5709a00032a", "3z014890b7c597b45cdfddf2ffa9208ffe", "3z2122d6f19c15e2c8833db4381d47f8dc4b9b99d33d061f9703a2e57daad55fa1", "3z9c1bb2d3338be4ffe549db9529540900", "0.0");
          } catch (e) {}
        }
      };
    } else {
      s.onload = function () {
        try {
          loadwaprops("3zf95eabaef38a64147ef7c5709a00032a", "3z014890b7c597b45cdfddf2ffa9208ffe", "3z2122d6f19c15e2c8833db4381d47f8dc4b9b99d33d061f9703a2e57daad55fa1", "3z9c1bb2d3338be4ffe549db9529540900", "0.0");
        } catch (e) {}
      };
    }
    s.src = p + "//ma.zoho.com/hub/js/WebsiteAutomation.js";
    f.parentNode.insertBefore(s, f);
  `}
        </Script>
        <Head>
          <title>Makesy</title>
          <link rel="shortcut icon" type="image/svg" href="/logo.svg" />
          <meta
            name="description"
            content="Fashion app that is bringing custom wear  to the masses. 
            Makesy is redefining how customers order their fitted wear from the convenience of their home."
          />
          <meta
            name="keywords"
            content="Sewing ,Embroidery ,Crochet ,Knitting ,Quilting ,Beading ,Macrame,Lancing,Applique,Braiding,Weaving,Dyeing,Tatting"
          />
          <meta property="og:title" content=" Makesy " />
          <meta
            property="og:description"
            content="Fashion app that is bringing custom wear  to the masses. 
            Makesy is redefining how customers order their fitted wear from the convenience of their home."
          />
          <meta property="og:url" content="https://makesy.app/" />
          <meta property="og:image" content={makesyImg.img} />

          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta name="robots" content="index" />

          <meta name="geo.position" content="31.964746; 35.905758" />
          <meta name="geo.placename" content="Makesy Boulevard, Amman" />
          <meta name="geo.region" content="JO-AM" />
        </Head>
        <SettingsProvider>
          {isLoading ? (
            <p className="text-center m-5">Loading......</p>
          ) : (
            <Layout>
              <Component {...pageProps} />
            </Layout>
          )}
        </SettingsProvider>
      </I18nProvider>
    </>
  );
}

export default MyApp;
