import React, { useState } from "react";


export const OrderContext = React.createContext();

function SettingsProvider(props) {
    const [step, setStep] = useState(1);
    const [step1, setStep1] = useState({});
    const [step2, setStep2] = useState({});
    const [step3, setStep3] = useState({});
    
    
  
    const state = {
        step,
        setStep,
        step1,
        setStep1,
        step2,
        setStep2,
        step3,
        setStep3,
   
    };
    return (
      <OrderContext.Provider value={state}>
        {props.children}
      </OrderContext.Provider>
    );
  }
  
  export default SettingsProvider;