import Link from 'next/link'


const LinkHref = ({children,route})=>{
    return(
        <Link href={route} >
            {children}
        </Link>
    )
}

export default LinkHref;